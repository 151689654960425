import momentMsdate, { fromOADate } from 'moment-msdate'
import {
  TEXT_NULL_VALUE,
  TIMESTAMP_FORMATS
} from './constants'
import { parseZone } from 'moment-timezone'
import { validateValue } from './helpers'

const dateFormatter = (value, outputFormat, inputFormat = TIMESTAMP_FORMATS.ISO) => {
  if (
    !validateValue(value) ||
    !validateValue(outputFormat) ||
    !validateValue(inputFormat)
  ) return TEXT_NULL_VALUE

  // Respects a given timezone when converting, including UTC
  const date = parseZone(value, inputFormat)

  if (!date.isValid()) return TEXT_NULL_VALUE

  return date.format(outputFormat)
}

/**
* Formats a date string to have a short am/pm format
* @example "11/24/2023 10:15AM" -> "11/24/2023 10:15a"
* @param {string} dateString - date string containing am|AM or pm|PM
* @returns {string} the same date string with am|AM as "a" or pm|PM as "p"
*/
export const dateShortAmPm = dateString => dateString.toLowerCase().replace('am', 'a').replace('pm', 'p')

export const dateHourMinute = valueISO => dateFormatter(valueISO, 'hh:mma')
export const dateShortHourMinute = valueISO => dateFormatter(valueISO, 'h:mma')
export const dateHourMinuteEastern = valueISO => `${dateFormatter(valueISO, 'hh:mm A')} ET`
export const dateHourMinuteNoLeadingZero = valueISO => dateFormatter(valueISO, 'h:mm A')
export const dateHourMinuteEasternNoLeadingZero = valueISO => `${dateHourMinuteNoLeadingZero(valueISO)} ET`
export const dateMonth = valueISO => dateFormatter(valueISO, 'MM')
export const dateMonthAbbreviated = valueISO => dateFormatter(valueISO, 'MMM')
export const dateMonthDayAbbreviated = (value, inputFormat) => dateFormatter(value, TIMESTAMP_FORMATS.MONTHABBR_DAY, inputFormat)
export const dateMonthDayYear = valueISO => dateFormatter(valueISO, 'MM/DD/YYYY')
export const dateMonthDayYearAbbreviatedSlash = valueISO => dateFormatter(valueISO, 'M/DD/YY')
export const dateMonthDayYearAbbreviated = (value, inputFormat) => dateFormatter(value, TIMESTAMP_FORMATS.MONTHABBR_DAY_YEAR, inputFormat)
export const dateMonthDayYearAtTime = (value, inputFormat) => dateFormatter(value, TIMESTAMP_FORMATS.MONTH_DAY_YEAR_AT_TIME, inputFormat)
export const dateMonthDayYearFull = (value, inputFormat) => dateFormatter(value, TIMESTAMP_FORMATS.MONTHFULL_DAY_YEAR, inputFormat)
export const dateMonthDayYearHourMinute = valueISO => dateFormatter(valueISO, 'MM/DD/YYYY hh:mmA')
export const dateMonthDayYearShortHourMinute = valueISO => dateFormatter(valueISO, 'MM/DD/YYYY h:mmA')
export const dateHourMinuteMonthDayYear = valueISO => dateFormatter(valueISO, 'hh:mmA MMM DD, YYYY')
export const dateShortMonthDayYear = valueISO => dateFormatter(valueISO, 'MMM DD, YYYY')
export const dateShortMonthDayYearNoLeadingZero = valueISO => dateFormatter(valueISO, 'MMM D, YYYY')
export const dateMonthFull = valueISO => dateFormatter(valueISO, 'MMMM')
export const dateMonthYear = valueISO => dateFormatter(valueISO, 'MM/YYYY')
export const dateMonthYearAbbreviated = valueISO => dateFormatter(valueISO, 'MMM YYYY')
export const dateMonthYearAbbreviatedSlash = valueISO => dateFormatter(valueISO, 'MM/YY')
export const dateMonthYearFull = valueISO => dateFormatter(valueISO, 'MMMM YYYY')
export const dateYear = valueISO => dateFormatter(valueISO, 'YYYY')
export const dateYearTwoDigit = valueISO => dateFormatter(valueISO, 'YY')
export const dateYearMonthDay = valueISO => dateFormatter(valueISO, 'YYYYMMDD')
export const dateYearMonthDayHyphen = (value, inputFormat) => dateFormatter(value, TIMESTAMP_FORMATS.YEAR_MONTH_DAY_HYPHEN, inputFormat)
export const dateHourMinuteEasternMonthDay = valueISO => {
  const date = dateFormatter(valueISO, ' MM/DD/YYYY')
  const time = dateFormatter(valueISO, 'hh:mm A')

  return `${time} ET ${date}`
}

export const dateHourMinuteEasternShortMonthDayYear = valueISO => {
  const time = dateHourMinuteEasternNoLeadingZero(valueISO)
  const date = dateShortMonthDayYearNoLeadingZero(valueISO)

  return `${time} ${date}`
}

export const dateHourMinuteEasternMonthTrimmed = valueISO => {
  const date = dateFormatter(valueISO, TIMESTAMP_FORMATS.MONTHABBR_DAY_YEAR)
  const time = dateFormatter(valueISO, 'hh:mm A')

  return `${time} ET ${date}`
}

export const dateHourMinuteEasternMonthDayTrimmed = valueISO => {
  const date = dateFormatter(valueISO, ' MM/DD/YYYY')
  const time = dateFormatter(valueISO, 'h:mm A')

  return `${time} ET ${date}`
}
export const dateHourMinuteEasternMonthDayTrimmedSingleDigit = valueISO => {
  const date = dateFormatter(valueISO, ' M/D/YYYY')
  const time = dateFormatter(valueISO, 'h:mm A')

  return `${time} ET ${date}`
}
export const dateAsOfWithMarketClose = valueIso => `close on ${dateMonthDayYear(valueIso)}`
export const fromMsDate = fromOADate
export const toMsDate = date => momentMsdate(date).toOADate()
export const getQuarter = date => {
  const month = date?.getMonth() + 1
  return Math.ceil(month / 3)
}

import { COLOR } from '../../jpmc-qr-ui-common/styles/variables'

export const ARTICLE_SOURCE_MAP = {
  100: 'Comtex',
  3024: 'Benzinga',
  2936: 'MT Newswires'
}

export const BREAKPOINT_LARGE = 'large'
export const BREAKPOINT_MEDIUM = 'medium'
export const BREAKPOINT_SMALL = 'small'
export const BREAKPOINT_XLARGE = 'xlarge'
export const BREAKPOINT_XSMALL = 'xsmall'

export const BREAKPOINT_LARGE_PIXELS_MAX = 1199
export const BREAKPOINT_MEDIUM_PIXELS_MAX = 1023
export const BREAKPOINT_SMALL_PIXELS_MAX = 767
export const BREAKPOINT_XSMALL_PIXELS_MAX = 319

export const COOKIE_NAME_AUTHENTICATION = 'MarkitAPIUserToken'

export const ENVIRONMENT_ACCEPTANCE = 'acceptance'
export const ENVIRONMENT_ACCEPTANCE_2 = 'acceptance-2'
export const ENVIRONMENT_DEVELOPMENT = 'development'
export const ENVIRONMENT_LOCALHOST = 'localhost'
export const ENVIRONMENT_PRODUCTION = 'production'
export const ENVIRONMENT_PRODUCTION_CTC = 'production-ctc'
export const ENVIRONMENT_PRODUCTION_PTC = 'production-ptc'

export const ERROR_CODES = {
  BAD_REQUEST: 400,
  CUSTOM_ERROR: -1,
  OK: 200,
  NOT_FOUND: 404,
  PARTIAL_CONTENT: 206,
  UNAUTHORIZED: 401
}

export const KEY_CODES = {
  DOWN: 40,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  TAB: 9,
  UP: 38
}

export const CUSTOM_EVENT_PAGE_REFRESH = 'md-page-refresh'

export const KEY_SESSION_STORAGE_ACCESS_TOKEN = 'md-accesstoken'
export const KEY_SESSION_STORAGE_DATA_ENVIRONMENT_OVERRIDE = 'md-dataenvironmentoverride'
export const KEY_SESSION_STORAGE_INTERNAL_API = 'md-internalapi'
export const KEY_SESSION_STORAGE_JUMP_CLEAR_PERSISTED = 'md-clear-persisted'
export const KEY_SESSION_STORAGE_JUMP_PERSISTED_VALUES = 'md-persisted-values'
export const KEY_SESSION_STORAGE_PARENT_DOMAIN = 'md-parentdomain'
export const KEY_SESSION_STORAGE_SHOW_DEBUG_INFO = 'md-showdebuginfo'
export const KEY_SESSION_STORAGE_USER_TYPE = 'md-usertype'
export const KEY_SESSION_STORAGE_USER_EMAIL = 'md-alerts-email'
export const KEY_SESSION_STORAGE_CHART_TIMEFRAME = 'md-modcharts-timeframe'

export const MESSAGES = {
  ERROR: {
    colorIconFill: COLOR.RED1,
    colorText: COLOR.RED1,
    text: 'It looks like this part of our site isn\'t working right now.',
    textSecondary: 'Please reload the page. Thanks for your patience.'
  },
  INVALID_INPUT_CHARS: {
    colorIconFill: COLOR.RED4,
    colorText: COLOR.RED4,
    iconSize: '13px',
    marginLeft: '21px',
    padding: '0px',
    text: 'Please tell us a security using only letters and numbers.',
    textSize: '14px'
  }
}

export const NUMERICAL_NULL_VALUE = -32768

export const PERF_MEASURE_INDEX_TO_MOUNT = 'indexRenderToReactAppMount'
export const PERF_MEASURE_MOUNT_TO_RENDER = 'reactAppMountToContentRender'

export const PERF_MARK_APP_MOUNT = 'reactAppMount'
export const PERF_MARK_INDEX_RENDER = 'indexRender'

export const PERF_MEASURED_COMPONENTS_MAP = {
  '/stock/quote': [
    'StockQuoteHeader'
    // 'StockQuoteSectionsKeyStats'
  ],
  '/research/analyst': [
    'ResearchAnalystSearch'
  ],
  '/article': [
    'ArticleContent'
  ],
  '/crossrates': [
    'CrossRatesContent'
  ],
  '/international/stock/quote': [
    'InternationalStockQuoteHeader'
  ],
  '/market/calendar': [
    'MarketCalendarHeader'
  ],
  '/markets/overview': [
    'MarketsOverviewSummary'
  ],
  '/mutualfund/quote': [
    'MutualFundQuoteHeader'
  ],
  '/news/experience': [
    'NewsExperienceHeader'
  ],
  '/news/experience/article': [
    'NewsExperienceArticleBody'
  ],
  '/news/experience/search': [
    'NewsExperienceSearchResultsHeader'
  ],
  '/options': [
    'OptionsHeader'
  ],
  '/stock/chart': [
    'StockChartHeader'
  ],
  '/markets/summary/news': [
    'MarketsSummaryNewsArticleBody'
  ],
  '/watchlists': [
    'WatchlistsFilter'
  ]
}

export const POST_MESSAGE_COMMAND_ACK = 'ack'
export const POST_MESSAGE_COMMAND_AUTHENTICATE = 'authenticate'
export const POST_MESSAGE_COMMAND_ELEMENT_RENDER = 'elementRender'
export const POST_MESSAGE_COMMAND_GET_PARENT_OFFSET = 'getParentOffset'
export const POST_MESSAGE_COMMAND_GET_SIZE = 'getSize'
export const POST_MESSAGE_COMMAND_LOG_REQUEST = 'logService'
export const POST_MESSAGE_COMMAND_NAVIGATE = 'navigate'
export const POST_MESSAGE_COMMAND_PAGE_LOAD = 'pageLoad'
export const POST_MESSAGE_COMMAND_READY = 'ready'
export const POST_MESSAGE_COMMAND_REFRESH = 'refresh'
export const POST_MESSAGE_COMMAND_REQUEST_STATE = 'requestState'
export const POST_MESSAGE_COMMAND_RESIZE = 'resize'
export const POST_MESSAGE_COMMAND_SCROLL_TO = 'scrollTo'
export const POST_MESSAGE_COMMAND_STICKY_DATA = 'stickyData'
export const POST_MESSAGE_COMMAND_UPDATE_STATE = 'updateState'
export const POST_MESSAGE_COMMAND_USER_ACTIVITY = 'userActivity'
export const POST_MESSAGE_COMMAND_VIEW_RENDERED = 'viewRendered'
export const POST_MESSAGE_COMMAND_PAGE_RENDERED = 'quoteIframeRendered'

export const POST_MESSAGE_MESSAGE_CHILD_LOADED = 'Child loaded'
export const POST_MESSAGE_MESSAGE_CHILD_READY = 'Child ready'
export const POST_MESSAGE_MESSAGE_PARENT_LOADED = 'Parent loaded'
export const POST_MESSAGE_MESSAGE_PARENT_READY = 'Parent ready'
export const POST_MESSAGE_MESSAGE_USER_ACTIVITY = 'User activity detected on child document'

export const POST_MESSAGE_PROTOCOL_AUTHENTICATION = 'authentication'
export const POST_MESSAGE_PROTOCOL_CHILD_EVENT = 'childEvent'
export const POST_MESSAGE_PROTOCOL_DISPLAY_EVENT = 'displayEvent'
export const POST_MESSAGE_PROTOCOL_HANDSHAKE = 'handshake'
export const POST_MESSAGE_PROTOCOL_PERFORMANCE_EVENT = 'performanceEvent'
export const POST_MESSAGE_PROTOCOL_SESSION_TIMEOUT = 'sessionTimeout'

export const POST_MESSAGE_STATE_ID_QUOTE_WATCHLIST_VIEW = 'quoteWatchlistView'
export const POST_MESSAGE_STATE_ID_QUOTE_PAGE_VIEW = 'quotePageView'

export const POST_MESSAGE_REQUEST_STATE_CLOSE_VIEW = 'closeView'
export const POST_MESSAGE_ACTION_UPDATE_STATE_SET_EMAIL = 'setEmail'

export const REGEX_ALPHANUMERIC = /^[-\w\s]+$/

export const TEXT_NULL_VALUE = '—' // alt + 0151 results in em-dash
export const TEXT_DOUBLE_NULL_VALUE = '--'

export const TIMESTAMP_FORMATS = {
  ISO: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  MONTHABBR_DAY: 'MMM DD',
  MONTHABBR_DAY_YEAR: 'MMM DD, YYYY',
  MONTHFULL_DAY_YEAR: 'MMMM DD, YYYY',
  YEAR_MONTH_DAY_HYPHEN: 'YYYY-MM-DD',
  MONTH_DAY_YEAR_AT_TIME: 'MM/DD/YYYY [at] hh:mma [ET]'
}

export const TIMEZONE_MAP = {
  UTC: 'UTC',
  ET: 'America/New_York'
}

export const URL_PARAM_ACCESS_TOKEN = 'access_token'
export const URL_PARAM_CURRENCY_CATEGORY = 'currency_category'
export const URL_PARAM_DATA_ENVIRONMENT_OVERRIDE = 'data_environment_override'
export const URL_PARAM_DELETE_VIEW_ID = 'delete_view_id'
export const URL_PARAM_EMPLOYEE_PILOT = 'ep'
export const URL_PARAM_VALIDATOR_PILOT = 'vp'
export const URL_PARAM_GLOBAL_RESEARCH_ENABLED = 'global_research_enabled'
export const URL_PARAM_HIDE_HEADER = 'hide_header'
export const URL_PARAM_HIDE_STICKY_HEADER = 'hide_sticky_header'
export const URL_PARAM_INTEGRATION_SOURCE = 'integration_source'
export const URL_PARAM_INTERNAL_API = 'internal_api'
export const URL_PARAM_EVENT_TYPE_ID = 'event_type_id'
export const URL_PARAM_MOCK_DATA = 'mock_data'
export const URL_PARAM_PARENT_READY = 'parent_ready'
export const URL_PARAM_READ_ONLY = 'read_only'
export const URL_PARAM_RESEARCH_TYPE = 'research_type'
export const URL_PARAM_SELECTED_DATE = 'selected_date'
export const URL_PARAM_SHOW_DEBUG_INFO = '..showdebuginfo..'
export const URL_PARAM_STAND_ALONE = 'stand_alone'
export const URL_PARAM_SYMBOL = 'symbol'
export const URL_PARAM_WATCHLIST_ID = 'watchlistId'
export const URL_PARAM_VENUE_XID = 'venue_xid'

// US Indices
export const XID_INDEX_DOW = '599362'
export const XID_INDEX_NASDAQ = '579435'
export const XID_INDEX_SP500 = '593933'

// Europe Indices
export const XID_INDEX_CAC_40 = '585994'
export const XID_INDEX_DAX = '569857'
export const XID_INDEX_FTSE_100 = '572009'
export const XID_INDEX_SMI = '567786'

// Asia Indices
export const XID_INDEX_HANG_SENG = '568838'
export const XID_INDEX_NIKKEI = '576473'

export const CLASSIFICATION_ID_DEBT = 1
export const CLASSIFICATION_ID_DERIVATIVE = 2
export const CLASSIFICATION_ID_STOCK = 3
export const CLASSIFICATION_ID_MUTUAL_FUND = 5
export const CLASSIFICATION_ID_INDEX = 7
export const CLASSIFICATION_ID_ETF = 18
export const CLASSIFICATION_ID_CLOSED_FUND = 84
export const CLASSIFICATION_IDS_NO_INSURANCE = [
  CLASSIFICATION_ID_DEBT,
  CLASSIFICATION_ID_DERIVATIVE,
  CLASSIFICATION_ID_STOCK,
  CLASSIFICATION_ID_MUTUAL_FUND,
  CLASSIFICATION_ID_INDEX,
  CLASSIFICATION_ID_ETF,
  CLASSIFICATION_ID_CLOSED_FUND
]

export const X_TYPE_NAMES = {
  MONEY_MARKET_FUND: {
    ID: 'MMF',
    NAME: 'Money Market Fund'
  },
  OPEN_FUND: {
    ID: 'OF',
    NAME: 'Open Fund'
  }
}

export const XREF_CLASSIFICATIONS = {
  CLOSED_END_FUND: {
    ID: 'cef',
    NAME: 'Closed End Funds'
  },
  ETF: {
    ID: 'etf',
    NAME: 'ETF'
  },
  MUTUALFUND: {
    ID: 'mutualfund',
    NAME: 'Fund'
  },
  STOCK: {
    ID: 'stock',
    NAME: 'Equity'
  },
  DEBT: {
    ID: 'debt',
    NAME: 'Debt'
  },
  DERIVATIVE: {
    ID: 'derivative',
    NAME: 'Derivative'
  },
  INDEX: {
    ID: 'index',
    NAME: 'Index'
  }
}

export const MAP_INDEX_NAMES = {
  [XID_INDEX_DOW]: 'DJIA',
  [XID_INDEX_NASDAQ]: 'NASDAQ',
  [XID_INDEX_SP500]: 'S&P 500'
}

export const MAP_MORNINGSTAR_STYLE_BOND = {
  1: 'Limited Sensitivity High Quality',
  2: 'Moderate Sensitivity High Quality',
  3: 'Extensive Sensitivity High Quality',
  4: 'Limited Sensitivity Medium Quality',
  5: 'Moderate Sensitivity Medium Quality',
  6: 'Extensive Sensitivity Medium Quality',
  7: 'Limited Sensitivity Low Quality',
  8: 'Moderate Sensitivity Low Quality',
  9: 'Extensive Sensitivity Low Quality'
}

export const MAP_MORNINGSTAR_STYLE_EQUITY = {
  1: 'Large Value',
  2: 'Large Blend',
  3: 'Large Growth',
  4: 'Mid Value',
  5: 'Mid Blend',
  6: 'Mid Growth',
  7: 'Small Value',
  8: 'Small Blend',
  9: 'Small Growth'
}

export const INVESTMENT_LA_ACCEPTANCE_REQUIRED = 'INVESTMENT_LA_ACCEPTANCE_REQUIRED'

export const ALERT_ITEM_DETAILS_MAP = {
  1: {
    frequency: 'One-time',
    alertCriteriaDescription: price => (`Price drops below ${price}`)
  },
  2: {
    frequency: 'One-time',
    alertCriteriaDescription: price => (`Price rises above ${price}`)
  },
  3: {
    frequency: 'Recurring',
    alertCriteriaDescription: () => ('Price reaches a new 52-week high')
  },
  4: {
    frequency: 'Recurring',
    alertCriteriaDescription: () => ('Price reaches a new 52-week low')
  },
  6: {
    frequency: 'Recurring',
    alertCriteriaDescription: pricePercentChange => (`Price moves ${pricePercentChange * 100}% since prior close`)
  },
  430: {
    frequency: 'Recurring',
    alertCriteriaDescription: (numOfDays, percent) => (`Volume exceeds ${numOfDays}-day average by ${percent * 100}%`)
  },
  0: {
    frequency: 'unknown',
    alertCriteriaDescription: () => ('Invalid criteria')
  }
}

export const MOVERS_SELECT_INDICES_US = [
  {
    label: 'NASDAQ',
    key: 'nasdaq',
    input: 'NASDAQ Composite Index'
  },
  {
    label: 'Micro cap',
    key: 'microcap',
    input: 'NASDAQ Micro Cap',
    isSubcategory: true,
    placeholder: 'NASDAQ Micro cap'
  },
  {
    label: 'Small cap',
    key: 'smallcap',
    input: 'NASDAQ Small Cap',
    isSubcategory: true,
    placeholder: 'NASDAQ Small cap'
  },
  {
    label: 'Medium cap',
    key: 'mediumcap',
    input: 'NASDAQ Mid Cap',
    isSubcategory: true,
    placeholder: 'NASDAQ Medium cap'
  },
  {
    label: 'Large cap',
    key: 'largecap',
    input: 'NASDAQ Large Cap',
    isSubcategory: true,
    placeholder: 'NASDAQ Large cap'
  },
  {
    label: 'Mega cap',
    key: 'megacap',
    input: 'NASDAQ Mega Cap',
    isSubcategory: true,
    placeholder: 'NASDAQ Mega cap'
  },
  {
    label: 'S&P 500',
    key: 's&p500',
    input: 'S%26P 500 Index (SPGlobal)'
  },
  {
    label: 'DJIA',
    key: 'djia',
    input: 'DJIA Index (SPGlobal)'
  }
]

export const SYMBOL_LOGOS_FORMAT = [
  'mark_composite_light',
  'logo_light',
  'logo_vector_light',
  'mark_composite_dark',
  'logo_dark',
  'logo_vector_dark',
  'mark_light',
  'mark_dark',
  'mark_vector_dark',
  'mark_vector_light'
]

export const DEFAULT_LOGO_WIDTH = 300
export const DEFAULT_LOGO_HEIGHT = 300

export const DELIVERY_TYPE = {
  HTTP: 'HTTP',
  EMAIL: 'EMAIL'
}

export const DESTINATION_ID = {
  JPMC_PRIMARY: 'jpmc-primary',
  JPMC_JSON_DELIVERY: 'jpmc-jsondelivery'
}

export const FORMATS = {
  TEXT: 'TEXT'
}

export const USERTYPE = {
  CPO: 'CPO',
  JPO: 'JPO'
}

export const COLUMN_SORT_ORDER = {
  TYPE: 4,
  TRIGGER_DATE: 1,
  FREQUENCY: 5,
  CREATED_DATE: 6
}

export const ALERT_TABLE_COLUMN_NAME = {
  CREATED: 'insertDate',
  ITEM: 'item',
  ALERT: 'alertType',
  FREQUENCY: 'item'
}

export const NAVIGATE_QUOTE_PAGE = 'quote'

export const ACTION_NAMES = {
  ALERTS: 'alerts',
  ALERTS_CREATE: 'alertsCreate',
  ALERTS_HISTORY: 'alertsHistory',
  QUOTE: 'quote',
  TRADE: 'trade',
  INVESTMENT_SEARCH: 'investmentSearch',
  ALERTS_PROFILE_AND_SETTINGS: 'alertsProfileAndSettings',
  QUOTE_RESEARCH: 'quoteResearch',
  PORTFOLIO_NEWS_STATUS: 'portfolioNewsStatus',
  ASSET_HOLDING_STATUS: 'assetHoldingStatus',
  NEWS_EXPERIENCE: 'newsExperience',
  NEWS_ARTICLE: 'newsArticle',
  NEWS_SEARCH_RESULTS: 'newsSearchResults',
  CIB_RESEARCH_DOC: 'cibResearchDoc',
  DOWNLOAD: 'download',
  QUOTE_FULL_CHART: 'quoteFullChart',
  QUOTE_ARTICLE: 'quoteArticle',
  QUOTE_OPTIONS_CHAIN: 'quoteOptionsChain',
  EXTERNAL_LINK: 'externalLink',
  CLIENT_ACTION_FRAMEWORK_LOG: 'clientActionFrameworkLog',
  CROSSRATES: 'crossrates',
  INTERNATIONAL_STOCK_QUOTE: 'internationalStockQuote',
  MID_DAY_BRIEF: 'midDayBrief',
  LEARNING_INSIGHTS: 'learningInsights',
  TODAYS_MOVERS: 'todaysMovers',
  SEE_ANALYSTS_DETAILS: 'seeAnalystsDetails',
  CFR_RESEARCH: 'cfraResearch',
  WATCH_LISTS: 'watchlists',
  CALENDAR: 'calendar',
  PAGE_LOAD: 'pageLoad'
}

export const CUSTOM_POPPER_PLACEMENT_TYPES = {
  TOP_START: 'top-start',
  TOP: 'top',
  TOP_END: 'top-end',
  LEFT_START: 'left-start',
  LEFT: 'left',
  LEFT_END: 'left-end',
  RIGHT_START: 'right-start',
  RIGHT: 'right',
  RIGHT_END: 'right-end',
  BOTTOM_START: 'bottom-start',
  BOTTOM: 'bottom',
  BOTTOM_END: 'bottom-end'
}

export const analystRatingMapping = {
  buy: [ 'Overweight', 'Buy', 'Outperform', 'Market Outperform', 'Positive', 'Sector Outperform' ],
  sell: [ 'Underweight', 'Sell', 'Underperform', 'Market Underperform', 'Sector Underperform' ],
  hold: [ 'Neutral', 'Hold', 'Equal-weight', 'Market Perform', 'Market Weight', 'Sector Weight', 'Negative', 'Sector Perform', 'In Line' ]
}

export const INVALID_CHARACTER_ERROR = 'invalid_characters'

export const HEADER_TIME_FRAMES = {
  '1D': {
    textValue: 'Today',
    quoteValue: 'today'
  },
  '5D': {
    textValue: 'Past week',
    quoteValue: 'oneWeek'
  },
  '1M': {
    textValue: 'Past month',
    quoteValue: 'oneMonth'
  },
  '3M': {
    textValue: 'Past 3 months',
    quoteValue: 'threeMonth'
  },
  '1Y': {
    textValue: 'Past year',
    quoteValue: 'oneYear'
  },
  '3Y': {
    textValue: 'Past 3 years',
    quoteValue: 'threeYear'
  },
  '5Y': {
    textValue: 'Past 5 years',
    quoteValue: 'fiveYear'
  },
  '10Y': {
    textValue: 'Past 10 years',
    quoteValue: 'tenYear'
  },
  // CHS-4799 - $/% change in Max Charts, match 1D
  Max: {
    textValue: 'Max',
    quoteValue: 'max'
  }
}

export const COMMON_ICON_HAT = {
  down: 'down',
  refresh: 'Refresh data',
  up: 'up'
}

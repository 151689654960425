import TagManager from 'react-gtm-module'
import moment, {
  parseZone,
  utc
} from 'moment-timezone'
import {
  ACTION_NAMES,
  ALERT_ITEM_DETAILS_MAP,
  BREAKPOINT_LARGE,
  BREAKPOINT_LARGE_PIXELS_MAX,
  BREAKPOINT_MEDIUM,
  BREAKPOINT_MEDIUM_PIXELS_MAX,
  BREAKPOINT_SMALL,
  BREAKPOINT_SMALL_PIXELS_MAX,
  BREAKPOINT_XLARGE,
  BREAKPOINT_XSMALL,
  BREAKPOINT_XSMALL_PIXELS_MAX,
  DESTINATION_ID,
  ENVIRONMENT_DEVELOPMENT,
  KEY_SESSION_STORAGE_DATA_ENVIRONMENT_OVERRIDE,
  KEY_SESSION_STORAGE_INTERNAL_API,
  KEY_SESSION_STORAGE_PARENT_DOMAIN,
  KEY_SESSION_STORAGE_SHOW_DEBUG_INFO,
  KEY_SESSION_STORAGE_USER_TYPE,
  NAVIGATE_QUOTE_PAGE,
  NUMERICAL_NULL_VALUE,
  POST_MESSAGE_COMMAND_NAVIGATE,
  POST_MESSAGE_COMMAND_PAGE_RENDERED,
  POST_MESSAGE_COMMAND_SCROLL_TO,
  POST_MESSAGE_COMMAND_UPDATE_STATE,
  POST_MESSAGE_PROTOCOL_CHILD_EVENT,
  POST_MESSAGE_PROTOCOL_DISPLAY_EVENT,
  TEXT_NULL_VALUE,
  TIMESTAMP_FORMATS,
  TIMEZONE_MAP,
  USERTYPE,
  XREF_CLASSIFICATIONS
} from './constants'
import { dateMonthDayYear, dateMonthDayYearAbbreviated, dateMonthDayYearFull } from './dateFormatters'
import { isValidatorPilot } from './pilotTesting'
import {
  number,
  numeralize,
  priceComma
} from './numberFormatters'
import { v4 as uuidv4 } from 'uuid'

/**
 * Retrieves feature flag from configuration
 * @param {string} path how to find value in the object for example property1.nested1.nestedN
 * @returns {any} - any value stored under given path
 */
export const getFeatureFlag = path => getObjectPathValue(window.MD?._featureFlags, path, null)

/**
 * Convert a date from UTC to a specific timezone
 *
 * @param {string} value - the date value to convert
 * @param {string} targetTimezone  - target timezone to convert to
 * @return {Object} - a moment js object representing time in target-time-zone
 */
export const convertDateFromUTC = (valueUTC, targetTimezone) => {
  if (!validateValue(valueUTC) || !validateValue(targetTimezone)) return TEXT_NULL_VALUE

  const date = utc(valueUTC)

  if (!date.isValid()) return TEXT_NULL_VALUE

  // toISOString(true) prevents conversion back to UTC
  return date.tz(targetTimezone).toISOString(true)
}

/**
 * Converts an object into a search string.
 * Eg: {abc: 1, def: 2} is converted to abc=1&def=2
 *
 * @public
 * @function convertObjectToUrlParams
 * @param {Object} searchObject - object to be converted to search string
 * @returns {string} - search string
 */
export const convertObjectToUrlParams = searchObject => searchObject && Object.keys(searchObject)
  .map(key => `${key}=${encodeURIComponent(searchObject[key])}`).join('&')

/**
 * Method used to get the URL for a data API request
 *
 * @public
 * @function getApiUrl
 * @param {string} path - url path
 * @param {Object} [params = {}] - object whose key values represent query string params
 * @param {Boolean} [isShowDebugDataSupported = true] - boolean flag indicated whether API supports debug data
 * @return String - required url
 */
export const getApiUrl = (path = '', params = {}, isShowDebugDataSupported = true) => {
  const dataEnvironmentOverride = sessionStorage.getItem(KEY_SESSION_STORAGE_DATA_ENVIRONMENT_OVERRIDE)
  let baseUrl = ''

  if (dataEnvironmentOverride === 'DEV') baseUrl = 'https://dev-api.markitdigital.com'
  else if (dataEnvironmentOverride === 'QA') baseUrl = 'https://qa-api.markitdigital.com'
  else if (dataEnvironmentOverride === 'PROD') baseUrl = 'https://api.markitdigital.com'

  if (!baseUrl) {
    baseUrl = 'https://dev-api.markitdigital.com'

    const internalApi = sessionStorage.getItem(KEY_SESSION_STORAGE_INTERNAL_API)

    if (window.MD) {
      baseUrl = internalApi && internalApi === 'true'
        ? window.MD.apiBaseUrl
        : window.MD.apiProxyBaseUrl
    }
  }

  const showDebugInfo = sessionStorage.getItem(KEY_SESSION_STORAGE_SHOW_DEBUG_INFO)
  const urlParams = { ...params }

  if (isShowDebugDataSupported && showDebugInfo === 'true') urlParams['..showdebugdata..'] = 'on'

  const urlParamsChar = path.indexOf('?') > -1 ? '&' : '?'
  const urlParamsString = convertObjectToUrlParams(urlParams)

  return urlParamsString
    ? `${baseUrl}${path}${urlParamsChar}${urlParamsString}`
    : `${baseUrl}${path}`
}

/**
 * Method used to get a breakpoint for a particular width
 * @param {Content width} width
 * @return String
 */
export const getBreakpoint = width => {
  if (!width && width !== 0) return null

  if (width <= BREAKPOINT_XSMALL_PIXELS_MAX) return BREAKPOINT_XSMALL
  if (width <= BREAKPOINT_SMALL_PIXELS_MAX) return BREAKPOINT_SMALL
  if (width <= BREAKPOINT_MEDIUM_PIXELS_MAX) return BREAKPOINT_MEDIUM
  if (width <= BREAKPOINT_LARGE_PIXELS_MAX) return BREAKPOINT_LARGE

  return BREAKPOINT_XLARGE
}

/**
 * Method used to create a performance mark and measure entry for PerformanceObserver to record
 * @param {String} measurementName
 * @param {String} startMark
 * @param {String} endMark
 */
export const performanceMarkAndMeasure = (measurementName, startMark, endMark) => {
  if (
    !performance ||
    !measurementName ||
    !startMark ||
    !endMark
  ) return

  try {
    performance.mark(endMark)
    performance.measure(
      measurementName,
      {
        detail: { component: endMark },
        start: startMark,
        end: endMark
      }
    )
  } catch (error) {
    error && console.error('PerformanceObserver: ', error.message)
  }
}

const enUsLocale = 'en-US'
const estTimezone = 'America/New_York'
/**
 * Convert a date or date string to be in the EST timezone.
 * @param {Date | string} date - date to convert to EST
 * @returns {Date} passed in Date but in EST
 */
export const getDateInEST = (date = new Date()) => {
  const dateToConvert = date instanceof Date ? date : new Date(date)
  return new Date(dateToConvert.toLocaleString(enUsLocale, { timeZone: estTimezone }))
}

/**
 * Returns the user's local datetime in the EST timezone. MomentJS does not
 * reliably do this when using `moment().tz('America/New_York')` and we need to
 * compare this date against what we get back from Modcharts event arguments.
 * @returns {Date}
 */
export const getCurrentDateInEST = () => getDateInEST()

/**
 * Method used to return the current datetime
 * @return String - datetime in UTC timezone in ISO format
 */
export const getCurrentDateTimeUTC = () => utc().toISOString()

/**
 * Method used to get the current environment based on window location host
 * @return String
 */
export const getEnvironment = () => (window.MD ? window.MD.environment : ENVIRONMENT_DEVELOPMENT)

/**
 * Mimics the lodash _.get function and returns the value present inside an object
 * Eg: getObjectPathValue(obj, 'a.b.d.2.e', null) will return value present in the path a->b>d>2(array-position)->e
 *
 * @param {Object} obj - object from which value needs to be parsed
 * @param {String} path - path from which value needs to be fetched
 * @param {any} defaultValue - default value if value is falsy
 * @return {any} - returns the value present at the path
 */
export const getObjectPathValue = (obj = {}, path, defaultValue) => {
  const pathStr = path || ''
  const pathParts = pathStr.split('.')
  let objToParse = obj
  pathParts.some(pathPart => {
    objToParse = objToParse[pathPart]
    return !objToParse
  })
  if (!objToParse && defaultValue) objToParse = defaultValue

  return objToParse
}

/**
 * Method used to get an object from a query string
 *
 * @param {Query string} queryString
 * @return Object
 */
export const getQueryStringAsObject = queryString => {
  if (!queryString) return undefined

  const paramObject =
  (
    /^[?#]/.test(queryString)
      ? queryString.slice(1)
      : queryString
  )
    .split('&')
    .reduce((
      params,
      param
    ) => {
      const [ key, value ] = param.split('=')
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''

      return params
    }, {})

  return paramObject
}

/**
 * Method used to get a query string parameter value
 * @param {URL parameter key} key
 * @return String
 */
export const getQueryStringParameterValue = key => {
  if (!window.location.search) return undefined

  const params =
  (
    /^[?#]/.test(window.location.search)
      ? window.location.search.slice(1)
      : window.location.search
  )
    .split('&')
    .reduce((
      params,
      param
    ) => {
      const [ key, value ] = param.split('=')
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
      return params
    }, {})

  if (!params || Object.keys(params).length < 1) return undefined

  return params[key]
}

/**
 * Method used to get a time from now date stamp (e.g. 4 years ago)
 *
 * @param {value} Timestamp
 * @param {inputFormat} Timestamp format
 * @param {fullDateThreshold} Number of seconds before full date is used (86400 seconds = 24 hours)
 * @return String
 */
export const getTimeFromNow = (value, inputFormat = TIMESTAMP_FORMATS.ISO, fullDateThreshold = 86400) => {
  if (!validateValue(value)) return TEXT_NULL_VALUE

  const date = parseZone(value, inputFormat)

  if (!date.isValid()) return TEXT_NULL_VALUE

  const secondsAgo = moment().diff(date, 'seconds')

  // Return full date if full date threshold is met
  if (secondsAgo >= fullDateThreshold && isValidatorPilot()) {
    return dateMonthDayYearAbbreviated(value, inputFormat)
  } else if (secondsAgo >= fullDateThreshold) {
    return dateMonthDayYearFull(value, inputFormat)
  }

  if (secondsAgo < 60) return 'Moments ago'
  if (secondsAgo < 120) return '1 minute ago'
  if (secondsAgo < 3600) return `${Math.floor(secondsAgo / 60)} minutes ago`
  if (secondsAgo < 7200) return '1 hour ago'
  if (secondsAgo < 86400) return `${Math.floor(secondsAgo / 3600)} hours ago`
  if (secondsAgo < 172800) return '1 day ago'

  // Default to moment fromNow for 2 days ago or more
  return date.fromNow()
}

/**
 * Method used to get a UUID
 *
 * @return {Object} - UUID (RFC 4122 v4)
 */
export const getUuid = () => uuidv4()

/**
 * Method used to parse a string into JSON
 * @param {JSON string to parse} value
 * @param {Default JSON object in case of failure} defaultValue
 * @return Object
 */
export const parseJson = (value, defaultValue = {}) => {
  let parsedValue

  try {
    parsedValue = JSON.parse(value)
  } catch {
    parsedValue = defaultValue
  }

  return parsedValue
}

/**
 * Method used to add query parameters to search string
 *
 * @public
 * @param {Object} params
 * @param {Query string} queryString
 * @return String
 */
export const setQueryStringParameters = (params, queryString) => {
  if (!queryString) return undefined
  if (!params) return queryString

  const paramObject = getQueryStringAsObject(queryString)

  Object.keys(params).forEach(key => {
    if (params[key]) paramObject[key] = params[key]
  })

  return `?${convertObjectToUrlParams(paramObject)}`
}

/**
 * Method used to remove query parameters from search string
 *
 * @public
 * @param {Array} params
 * @param {Query string} queryString
 * @return String
 */
export const removeQueryStringParameters = (params, queryString) => {
  if (!queryString) return undefined
  if (!params || !params.length) return queryString

  const paramObject = getQueryStringAsObject(queryString)

  params.forEach(item => {
    if (paramObject[item]) delete paramObject[item]
  })

  return `?${convertObjectToUrlParams(paramObject)}`
}

/**
 * Method used to send round number to specific decimal place
 * @param {Value to be rounded} value
 * @param {Decimal place} precision
 */
export const roundNumber = (value, precision = 0) => {
  const formattedValue = number(value, precision)
  if (formattedValue === TEXT_NULL_VALUE) return value
  return numeralize(formattedValue)
}

/**
 * Method used to send postMessage
 * @param {Message to be sent} message
 */
export const sendPostMessage = (
  container = window.parent,
  message,
  targetOrigin = sessionStorage.getItem(KEY_SESSION_STORAGE_PARENT_DOMAIN)
) => {
  if (!container || !message || !targetOrigin) return

  container.postMessage(message, targetOrigin)
}

export const sendPostMessage2 = (
  message,
  container = window.parent,
  targetOrigin = sessionStorage.getItem(KEY_SESSION_STORAGE_PARENT_DOMAIN)
) => {
  if (!message) return

  container.postMessage(message, targetOrigin)
}

/**
 * Method used to sort array string values alphabetically
 * @param {String 1} a
 * @param {String 2} b
 * @return Number indicating sort order for array.sort
 */
export const sortAlphabetically = (a, b) => {
  const aLow = a.toLowerCase()
  const bLow = b.toLowerCase()

  if (aLow < bLow) return -1
  if (aLow > bLow) return 1
  return 0
}

/**
 * Method used to sort array string values alphabetically descending
 * @param {String 1} a
 * @param {String 2} b
 * @return Number indicating sort order for array.sort
 */
export const sortAlphabeticallyDesc = (a, b) =>
  sortAlphabetically(a, b) * -1

/**
 * Method used to sort array number values
 * @param {int 1} a
 * @param {int 2} b
 * @return Number indicating sort order for array.sort
 */
export const sortNumerically = (a, b) => {
  if (a < b) return -1
  if (a > b) return 1
  return 0
}

/**
 * Method used to sort array number values descending
 * @param {int 1} a
 * @param {int 2} b
 * @return Number indicating sort order for array.sort
 */
export const sortNumericallyDesc = (a, b) =>
  sortNumerically(a, b) * -1

/**
 * Method used to sort array date values ascending
 * @param {int 1} a
 * @param {int 2} b
 * @return Number indicating sort order for array.sort
 */
export const sortByDate = (a, b) =>
  new Date(a) - new Date(b)

/**
 * Method used to sort array date values descending
 * @param {int 1} a
 * @param {int 2} b
 * @return Number indicating sort order for array.sort
 */
export const sortByDateDesc = (a, b) =>
  sortByDate(a, b) * -1
/**
 * Method used to validate a value
 *
 * @public
 * @function validateValue
 * @param {any} value - value to test
 * @return Boolean - value is not undefined, not null, not -32768
 */
export const validateValue = value =>
  typeof value !== 'undefined' &&
  value !== null &&
  value !== '-32768' &&
  value !== NUMERICAL_NULL_VALUE

/**
 * Method used to validate if the value is a non-empty string.
 * @public
 * @function validateString
 * @param {any} value - Value to test.
 * @return {Boolean} - Returns true if the value is a string and is not an empty string (after trimming).
 */

export const validateString = value =>
  typeof value === 'string' && value.trim() !== ''

/**
 * Method used to validates a mixed value that can be a string, number, null, or undefined.
 * @public
 * @function validateMixedValue
 * @param {any} value - The value to validate.
 * @return {Boolean} - Returns true if the value is not undefined, null, If a string, it must be non-empty (after trimming), If a number, it must be a valid number and not equal to NUMERICAL_NULL_VALUE.
 */
export const validateMixedValue = value => {
  if (!validateValue(value)) {
    return false
  }
  if (typeof value === 'string') {
    return validateString(value)
  }
  if (typeof value === 'number') {
    return !isNaN(value) && value !== NUMERICAL_NULL_VALUE
  }
  return false
}

/**
 * Method used to validate a moment datetime is midnight
 *
 * @public
 * @function validateDatetimeMidnight
 * @param {any} value - datetime value to test
 * @return Boolean - datetime value is midnight
 */
export const validateDatetimeMidnight = value =>
  validateValue(value) &&
  value.isValid() &&
  value.hours() === 0 &&
  value.minutes() === 0 &&
  value.seconds() === 0

/**
 * Method used to scroll window to top
 * @param {Event from click} event
 */
export const windowScrollToTop = event => {
  event.preventDefault()
  sendPostMessage(
    window.parent,
    {
      command: POST_MESSAGE_COMMAND_SCROLL_TO,
      protocol: POST_MESSAGE_PROTOCOL_DISPLAY_EVENT
    },
    sessionStorage.getItem(KEY_SESSION_STORAGE_PARENT_DOMAIN)
  )
}

/**
 * Method used to update parent page state
 * @param {State id} id
 */
export const sendStateUpdate = id => {
  sendPostMessage(
    window.parent,
    {
      command: POST_MESSAGE_COMMAND_UPDATE_STATE,
      context: { id },
      protocol: POST_MESSAGE_PROTOCOL_CHILD_EVENT
    },
    sessionStorage.getItem(KEY_SESSION_STORAGE_PARENT_DOMAIN)
  )
}

export const sendRenderedMessage = path => {
  sendPostMessage(
    window.parent,
    {
      command: POST_MESSAGE_COMMAND_PAGE_RENDERED,
      context: {
        action: ACTION_NAMES.CLIENT_ACTION_FRAMEWORK_LOG,
        pathName: path
      },
      protocol: POST_MESSAGE_PROTOCOL_CHILD_EVENT
    },
    sessionStorage.getItem(KEY_SESSION_STORAGE_PARENT_DOMAIN)
  )
}

export const getAlertCriteria = (criteria, item) => {
  const { alertCriteriaDescription } = ALERT_ITEM_DETAILS_MAP[item] || ALERT_ITEM_DETAILS_MAP[0]
  switch (item) {
    case 1:
    case 2: {
      return alertCriteriaDescription(priceComma(criteria.price, 2))
    }
    case 6: {
      return alertCriteriaDescription(criteria.pricePercentChange)
    }
    case 430: {
      return alertCriteriaDescription(criteria.mvaInterval || criteria.mvainterval, criteria.percent)
    }
    default: {
      return alertCriteriaDescription()
    }
  }
}

export const getTimeDifference = date => {
  const incomingDate = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss')
  const currentEST = moment().tz(TIMEZONE_MAP.ET).format('YYYY-MM-DDTHH:mm:ss')

  const secondsAgo = moment.duration(moment(currentEST).diff(incomingDate))

  const durationAsHours = secondsAgo._data.hours
  const durationAsMinutes = secondsAgo._data.minutes
  const durationAsSeconds = secondsAgo._data.seconds

  let relativeDuration = ''

  if (durationAsHours !== 0) {
    relativeDuration = durationAsHours + `${durationAsHours === 1 ? ' hour ' : ' hours '}`
  } else if (durationAsHours < 1 && durationAsMinutes !== 0) {
    relativeDuration = durationAsMinutes + `${durationAsMinutes === 1 ? ' minute ' : ' minutes '}`
  } else if (durationAsHours < 1 && durationAsMinutes < 1) {
    relativeDuration = durationAsSeconds + `${durationAsSeconds === 1 ? ' second ' : ' seconds '}`
  }
  return relativeDuration
}

export const getDateInTodayORYesterdayFormat = date => {
  let showDate = dateMonthDayYear(date)
  const exDateMoment = moment(date)
  const today = moment().clone().startOf('day')
  const yesterday = moment().clone().subtract(1, 'days').startOf('day')
  if (exDateMoment.isSame(yesterday, 'd')) showDate = 'Yesterday'
  else if (exDateMoment.isSame(today, 'd')) showDate = 'Today'
  return showDate
}

export const getDestinationID = () => {
  const shouldJPOUseNewUI = getFeatureFlag('alerts.shouldJPOUseNewUI')
  const userType = sessionStorage.getItem(KEY_SESSION_STORAGE_USER_TYPE)
  if (userType === USERTYPE.CPO || shouldJPOUseNewUI) {
    return DESTINATION_ID.JPMC_JSON_DELIVERY
  }
  return DESTINATION_ID.JPMC_PRIMARY
}

export const handleHoldingClick = (symbol, classificationName) => {
  let classificationId

  switch (classificationName) {
    case XREF_CLASSIFICATIONS.CLOSED_END_FUND.NAME: {
      classificationId = XREF_CLASSIFICATIONS.CLOSED_END_FUND.ID
      break
    }
    case XREF_CLASSIFICATIONS.ETF.NAME: {
      classificationId = XREF_CLASSIFICATIONS.ETF.ID
      break
    }
    case XREF_CLASSIFICATIONS.MUTUALFUND.NAME: {
      classificationId = XREF_CLASSIFICATIONS.MUTUALFUND.ID
      break
    }
    case XREF_CLASSIFICATIONS.STOCK.NAME: {
      classificationId = XREF_CLASSIFICATIONS.STOCK.ID
      break
    }
    case XREF_CLASSIFICATIONS.DEBT.NAME: {
      classificationId = XREF_CLASSIFICATIONS.DEBT.ID
      break
    }
    case XREF_CLASSIFICATIONS.DERIVATIVE.NAME: {
      classificationId = XREF_CLASSIFICATIONS.DERIVATIVE.ID
      break
    }
    case XREF_CLASSIFICATIONS.INDEX.NAME: {
      classificationId = XREF_CLASSIFICATIONS.INDEX.ID
      break
    }
    default: {
      classificationId = ''
      break
    }
  }

  sendPostMessage(
    window.parent,
    {
      command: POST_MESSAGE_COMMAND_NAVIGATE,
      context: {
        action: NAVIGATE_QUOTE_PAGE,
        classification: classificationId,
        symbol
      },
      protocol: POST_MESSAGE_PROTOCOL_CHILD_EVENT
    },
    sessionStorage.getItem(KEY_SESSION_STORAGE_PARENT_DOMAIN)
  )
}

export const stickyHeaderOnScroll = (header, scrollTop) => {
  scrollTop > 0
    ? header.classList.add('sticky')
    : header.classList.remove('sticky')
}

export const doesStringContainOnlyInvalidChars = text => {
  const nonAlphanumericPattern = /[^a-zA-Z0-9\-& ]+/

  return nonAlphanumericPattern.test(text) || text.trim() === ''
}

export const pushToDataLayer = tagManagerArgs => {
  TagManager.dataLayer(tagManagerArgs)
}

export const isObjectEmpty = obj => Object.keys(obj).length === 0

export const validateRequiredProperties = (obj, requiredProps) => requiredProps.every(prop => validateValue(obj[prop]))
